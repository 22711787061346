import React, { useEffect, useState } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Select } from 'antd';
import { DatePicker, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './Hotel.css';
import { useNavigate, useParams } from 'react-router-dom';
import Maps from '../Hotels/components/Maps/Maps';
import { Spinner } from '../../Components/Spinner/Spinner';
import * as moment from 'moment';
import { Modal } from 'antd';
import { Book } from './components/Book/Book';
import { useRecoilState } from 'recoil';
import { user } from '../../store/user';
import { Search } from '../Landing/components/Search/Search';
import { CustomImage } from '../../Components/Image/Image';
import { Hotels } from '../Landing/components/Hotels/Hotels';
import axiosInstance from '../../utils/axios';
import { currenices } from '../../utils/currency';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet-async';
import { BookingTime } from './components/BookingTime/BookingTime';
import { createTimeArray } from '../../utils/helpers';

export const Hotel = () => {
  const params = useParams();
  const [startDate, setStartDate] = useState(
    params.date ? dayjs(params.date) : dayjs()
  );
  const [queryDate, setQueryDate] = useState(
    params.date
      ? dayjs(params.date).format('YYYY-MM-DD')
      : dayjs().format('YYYY-MM-DD')
  );
  const [hotel, setHotel] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [book, setBook] = useState(null);
  const [guests, setGuests] = useState(1);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userData = useRecoilState(user);

  const handleOpenModal = () => {
    if (!userData[0]) {
      notification.warning({
        message: 'You must be logged in to be able to book a room!',
        placement: 'topLeft',
      });
    } else {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Hotel',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  useEffect(() => {
    if (!dayjs().isAfter(params.date, 'days')) {
      setHotel(null);
      axiosInstance.get(`api/v1/hotels/${params.id}`).then((res) => {
        if (res.data.results) {
          setHotel(res.data.results);
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        }
      });

      getAvailableRooms();
    } else {
      navigate(
        `/hotel/${params.id}/${dayjs().format('YYYY-MM-DD')}/${params.lat}/${
          params.lng
        }/${params.geoname}/${params.cc}`
      );

      window.location.reload();
    }
  }, [params]);

  const getAvailableRooms = () => {
    axiosInstance
      .get(`api/v1/hotels/booking/rooms/${queryDate}/${guests}/${params.id}`)
      .then((res) => {
        if (res.data.results) {
          setRooms(res.data.results);
        }
      });
  };

  useEffect(() => {
    getAvailableRooms();
  }, [queryDate, guests]);

  const handleChange = (value) => {
    setGuests(value);
  };

  const openBooking = (room) => {
    setBook({ ...room, date: queryDate });
    handleOpenModal();
  };

  return (
    <>
      <Helmet>
        <title>{hotel ? `${hotel.name} - ${hotel.city} Hourly Hotels | Nappr.io` : 'Book this 2 to 12-hour Hotel today! | Nappr.io'}</title>
        <meta 
          name="description" 
          content={hotel ? `Need a place to rest and relax in ${hotel.city}? Book the ${hotel.name} for 2 to 12 hours to nap, get some work done or use any of the hotel amenities at your convenience.` : 'Find the perfect hotel to rest, work, and enjoy premium amenities with Nappr.io.'} 
        />
        <meta 
          name="keywords" 
          content={hotel ? hotel.name : 'Nappr, day use hotel, hotel booking'}
        />
        <meta property="og:title" content={hotel ? `${hotel.name} - ${hotel.city} Day Use Rooms | Nappr.io` : 'Book this 2 to 12-hour Hotel today! | Nappr.io'} />
        <meta 
          property="og:description" 
          content={hotel ? `Need a place to rest and relax in ${hotel.city}? Book the ${hotel.name} for 2 to 12 hours to nap, get some work done and use any of the hotel amenities at your convenience.` : 'Find the perfect hotel to rest, work, and enjoy premium amenities with Nappr.io.'} 
        />
        <meta property="og:site_name" content="Nappr" />
        <meta property="og:image" content={hotel && hotel.images && hotel.images.length > 0 ? hotel.images[0].url : 'https://img.freepik.com/free-photo/beautiful-luxury-outdoor-swimming-pool-hotel-resort_74190-7433.jpg'} />
      </Helmet>

      <div className="hotels-search">
        <Search />
      </div>
      <div>
        {hotel ? (
          <div className="hotel-inside">
            <div className="hotel-inside-header">
              <h1>{hotel.name}</h1>
              <p>{`${hotel.address} ${hotel.city}, ${hotel.state}`}</p>
            </div>

            <div className="hotel-section">
              <div className="hotel-images">
                <div className="hotel-images-single">
                  <CustomImage url={hotel.images[0].url} title={hotel.images[0].title} />
                </div>

                <div className="hotel-images-multi">
                  <div>
                    <CustomImage url={hotel?.images[1]?.url} title={hotel?.images[1]?.title}/>
                  </div>

                  <div>
                    <CustomImage url={hotel?.images[2]?.url} title={hotel?.images[2]?.title}/>
                  </div>
                  <div>
                    <CustomImage url={hotel?.images[3]?.url} title={hotel?.images[3]?.title}/>
                  </div>
                  <div>
                    <CustomImage url={hotel?.images[4]?.url} title={hotel?.images[4]?.title}/>
                  </div>
                </div>
              </div>

              <div className="hotel-amenities">
                <h3>Hotel Amenities</h3>
                <div className="hotel-amenities-list">
                  {hotel.amenities.map((amnt,index) => (
                    <p key={index} className="hotel-amenities-list-tag">{amnt}</p>
                  ))}
                </div>
              </div>
            </div>

            <div className="hotel-d-m">
              <div className="hotel-d">
              <div
                style={{ marginBottom: '35px', marginTop: '25px' }}
                dangerouslySetInnerHTML={{
                  __html: hotel.description
                    ?.replace(/<wbr>/g, '') // Remove the <wbr> tag
                    .replace(/https:\/\/www\.hotelsbyday\.com\/(<wbr>)?en\/contact/g, 'https://www.nappr.io/contact-us')
                    .replace('https://www.hotelsbyday.com/en/contact', 'https://www.nappr.io/contact-us')
                    .replace('Hotels By Day', 'Nappr')
                    .replace('HotelsByDay', 'Nappr') || '' // Fallback to empty string
                }}
                />
                
                <div className="hotel-info-book">
                  <div className="section">
                    <div className="option-toggles">
                      <div>
                        <div className="option-room">
                          <div
                            style={{
                              fontSize: '14px',
                              fontWeight: 'bold',
                              marginBottom: '5px',
                            }}
                          >
                            Pick a date
                          </div>

                          <DatePicker
                            style={{ padding: 0 }}
                            disabledDate={(current) => {
                              let customDate = dayjs().format('YYYY-MM-DD');
                              return (
                                current &&
                                current < dayjs(customDate, 'YYYY-MM-DD')
                              );
                            }}
                            format={'MMMM D'}
                            suffixIcon={false}
                            bordered={false}
                            defaultPickerValue={startDate}
                            defaultValue={startDate}
                            placeholder="Pick a day"
                            onChange={(date, dateString) => {
                              setStartDate(date);
                              setQueryDate(dayjs(date).format('YYYY-MM-DD'));
                            }}
                          />
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            fontSize: '14px',
                            fontWeight: 'bold',
                            marginBottom: '5px',
                          }}
                        >
                          Guests
                        </div>
                        <Select
                          onChange={handleChange}
                          defaultValue={guests}
                          style={{ width: 70 }}
                          options={[
                            { value: 1, label: '1' },
                            { value: 2, label: '2' },
                            { value: 3, label: '3' },
                            { value: 4, label: '4' },
                            { value: 5, label: '5' },
                          ]}
                          suffixIcon={<UserOutlined />}
                        />
                      </div>
                    </div>

                    <div className="hotel-room-list">
                      {rooms.length ? (
                        rooms.map((r, index) => {
                          return (
                            <div
                              key={r.product_id}
                              className="hotel-room-options"
                              onClick={() => openBooking(r)}
                            >
                              <div>
                                <div>{r.product_name}</div>
                                <div className="check">
                                  Check in:{' '}
                                  {moment(r.check_in_time, 'HH:mm:ss').format(
                                    'h:mm A'
                                  )}
                                </div>
                                <div className="check">
                                  Check out:{' '}
                                  {moment(r.check_out_time, 'HH:mm:ss').format(
                                    'h:mm A'
                                  )}
                                </div>
                              </div>
                              <div>{`${currenices[r.currency].symbol} ${
                                r.rate
                              } `}</div>
                            </div>
                          );
                        })
                      ) : (
                        <h2 style={{ textAlign: 'center' }}>
                          There are no rooms available.
                        </h2>
                      )}
                    </div>

                  </div>
                </div>
              </div>
              <div
                className="hotel-map"
                style={{
                  width: '43%',
                  marginTop: '30px',
                  borderRadius: '10px',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                {hotel && <Maps hotels={[{ gps: hotel.gps }]} single />}
              </div>
            </div>

            <div style={{ marginTop: '100px' }}>
              <h1 style={{ marginBottom: '0' }}>Browse other hotels nearby</h1>
              <div className="landing-hotels-view">
                <p>
                  Enjoy all the hotel amenities for a fraction of an overnight
                  stay.
                </p>
              </div>
              <div className="landing-hotels-list">
                <Hotels activeId={hotel.hotel_id} date={params.date} />
              </div>
            </div>
          </div>
        ) : (
          <Spinner size="large" />
        )}

        {showModal && (
          <Modal
            open={showModal}
            onOk={handleCloseModal}
            onCancel={handleCloseModal}
            footer={null}
          >
            <Book
              book={book}
              handleCloseModal={handleCloseModal}
              hotel={hotel}
            />
          </Modal>
        )}
      </div>
    </>
  );
};
