import React, { useEffect, useState } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';
import { ShareAltOutlined } from '@ant-design/icons';
import '../Press/Press.css';
import './BlogContent.css';
import axiosInstance from '../../utils/axios';
import { Helmet } from 'react-helmet-async';
import { Link, useParams } from 'react-router-dom';
import { Spinner } from '../../Components/Spinner/Spinner';
import { useIsMobile } from '../../hooks/IsMobile';
import axios from 'axios';
import moment from 'moment';

export const BlogContent = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const isMobile = useIsMobile();

  function shareContent() {
    if (navigator.share) {
      navigator
        .share({
          title: blog.title,
          url: `${window.location.origin}/blog/${blog.id}/${blog.title
            .split(' ')
            .join('-')}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      // fallback for browsers that don't support the Web Share API
      console.log('Web Share API is not supported in your browser.');
    }
  }

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'BlogContent',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    axios
      .get(
        `https://squid-app-322vm.ondigitalocean.app/api/v1/blog/${params.id}`
      )
      .then((res) => {
        if (res?.data?.results) {
          setBlog(res.data.results);
        }
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="hotels-search">
        <Search />
      </div>
      {!blog && loading ? (
        <Spinner />
      ) : !blog && !loading ? (
        <div
          style={{
            minHeight: '500px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
          }}
        >
          This page does not exist
        </div>
      ) : (
        <>
          <Helmet>
            <title>{blog ? `${blog.title} - Nappr Blog` : 'Nappr Blog'}</title>
            <meta name="author" content="Nappr" />
            <meta name="description" content={blog.metadescription}/>
            <meta property="og:description" content={blog.metadescription} />
            <meta property="og:title" content={blog.title} />
            <meta property="og:site_name" content="Nappr"/>
          </Helmet>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: isMobile ? '100%' : '700px',
                padding: isMobile ? '20px 30px' : '50px 0',
              }}
            >
              <h1 style={{ color: '#11a5ea', marginBottom: '30px' }}>
                {blog.title}
              </h1>

              <div className="blog-timeshare">
                <span>{moment(blog.created_at).format('MMMM Do, YYYY')}</span>
                <div className="qr-share share-content">
                  <ShareAltOutlined size={20} onClick={() => shareContent()} />
                </div>
              </div>

              {blog.thumbnail ? (
                <img
                  src={blog.thumbnail}
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    maxHeight: '400px',
                  }}
                  alt={blog.alt_text || "Nappr"}
                />
              ) : (
                <img
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    maxHeight: '400px',
                  }}
                  src={require('../../assets/images/homepage/bg/header-mobile-light-blue-gradient.jpg')}
                  alt={blog.alt_text || "Nappr"}
                />
              )}

              <div
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: blog.content }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
