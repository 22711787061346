export async function getLatLngAndCountry(place) {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ address: place }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        const result = results[0];
        const lat = result.geometry.location.lat();
        const lng = result.geometry.location.lng();

        let countryCode = '';

        // Extract country code
        result.address_components.forEach((component) => {
          if (component.types.includes('country')) {
            countryCode = component.short_name; // e.g., "US"
          }
        });

        resolve({ lat, lng, country: countryCode });
      } else {
        reject(new Error('Location not found'));
      }
    });
  });
}
