import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PillowPin from '../../../../assets/images/pillow-icon.png';
import Pin from '../../../../assets/images/pin.svg';
import { currenices } from '../../../../utils/currency';
import { Hotel } from '../Hotel/Hotel';

const Maps = ({ hotels, single, loc }) => {
  const params = loc;

  const [activeMarker, setActiveMarker] = useState(null);
  const [mapCeneter, setMapCenter] = useState(
    single
      ? {
          lat: parseFloat(hotels[0].gps.lat),
          lng: parseFloat(hotels[0].gps.lon),
        }
      : params.lat
      ? {
          lat: parseFloat(params.lat),
          lng: parseFloat(params.lng),
        }
      : {
          lat: 40.721659,
          lng: -73.815632,
        }
  );

  useEffect(() => {
    if (params.lat && !single) {
      setMapCenter({
        lat: parseFloat(params.lat),
        lng: parseFloat(params.lng),
      });
    }
  }, [params]);

  const containerStyle = {
    width: '100%',
    height: single ? '300px' : '100%',
  };

  useEffect(() => {
    setActiveMarker(null);
  }, [hotels]);

  const handleActiveMarker = (marker) => {
    setActiveMarker(null);
    if (marker.hotel_id === activeMarker?.hotel_id) {
      return;
    }

    setMapCenter({
      lat: parseFloat(marker.gps.lat),
      lng: parseFloat(marker.gps.lon),
    });
    setActiveMarker(marker);
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyD2iKhSBTH3Gz5uZcGVca5S_-5DFv-uVJs',
  });

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(single ? 16 : 11);
  }, []);

  const getPriceRange = (hotel) => {
    var minRate = hotel.least_expensive_product?.total_price;
    var maxRate = hotel.most_expensive_product?.total_price;

    const price =
      minRate === maxRate
        ? `${currenices[hotel.most_expensive_product?.currency].symbol} ${
            hotel.most_expensive_product?.total_price
          }`
        : `${
            currenices[hotel.most_expensive_product?.currency].symbol
          } ${minRate} - ${
            currenices[hotel.most_expensive_product?.currency].symbol
          } ${maxRate}`;

    return price;
  };

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={mapCeneter}
      onLoad={onLoad}
      onClick={() => setActiveMarker(null)}
      options={{
        streetViewControl: false,
        zoomControl: false,
        disableDefaultUI: true,
      }}
    >
      <Marker
        icon={{
          url: Pin,
          //size: { width: 30, height: 30 },
          scaledSize: { width: 25, height: 25 },
        }}
        zIndex={10000}
        position={mapCeneter}
      />
      {hotels.map((hotel) => {
        return (
          <Marker
            icon={{
              url: PillowPin,
              //size: { width: 30, height: 30 },
              scaledSize: { width: 25, height: 25 },
            }}
            clickable={true}
            cursor={'pointer'}
            key={`hotel-${hotel.hotel_id}`} // Use a unique key
            position={{
              lat: parseFloat(hotel.gps.lat),
              lng: parseFloat(hotel.gps.lon),
            }}
            onClick={() => handleActiveMarker(hotel)}
          >
            {activeMarker?.hotel_id === hotel.hotel_id && !single && (
              <InfoWindowF options={{ maxWidth: '200px' }}>
                <Hotel
                  image={hotel.images?.[0]?.url}
                  imageTitle={hotel.images?.[0]?.title}
                  id={hotel.hotel_id}
                  key={`hotel-${hotel.hotel_id}`} // Use a unique key
                  name={hotel.name}
                  startDate={params.date}
                  address={`${hotel.address}`}
                  cityAndState={`${hotel.city}, ${hotel.state}`}
                  map
                  time={`${moment(
                    hotel.most_expensive_product?.check_in_time,
                    'HH:mm:ss'
                  ).format('h:mm A')} - ${moment(
                    hotel.most_expensive_product?.check_out_time,
                    'HH:mm:ss'
                  ).format('h:mm A')}`}
                  price={getPriceRange(hotel)}
                  distance={hotel.distance}
                />
              </InfoWindowF>
            )}
          </Marker>
        );
      })}

      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Maps);
